import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

class PoemaCard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    //const rNumber = Math.floor(Math.random() * 250 + 50)
    const rNumber = 180
    const poesia = this.props.poesia
    return (
     
        <div className="card bg-light">
           <Link to={`/poesias/${poesia.slug}`} className="card-link text-decoration-none">
          <div className="card-body">
            <h5 className="card-title text-info">{poesia.title}</h5>
            <p className="card-text">
              {poesia.plaintext.substring(0, rNumber) + "..."}
            </p>
            <p className="card-text text-right">Leia mais</p>
          </div>
          </Link>
        </div>
      
    )
  }
}

export default PoemaCard

PoemaCard.propTypes = {
  poesia: PropTypes.object.isRequired,
}
