import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PoemaCard from "../components/poema-card"
import {MetaData} from "../components/meta"

//http://webdesignernotebook.com/css/styling-a-poem-with-advanced-css-selectors/
// novas ideias

const PoemasPage = ({ data }) => {
  const [poemaSearch, setPoemaSearch] = useState("")

  const poesias = data.allGhostPost.edges

  const filteredPoesias = poesias.filter(({ node }) => {
    return node.title.toLowerCase().indexOf(poemaSearch.toLowerCase()) !== -1
  })

  const location = {
    pathname: '/poesias/',
    search:'',
    hash:''
  }

  return (
    <Layout>
      <MetaData data={data} location={location} type="series" />
      <div className="row">
        <div className="col">
          <img
            className="img-fluid"
            src={require(`../images/poesias_011.jpg`)}
            alt={`poesias`}
          />
        </div>
      </div>

      <div className="row pt-3">
        <div className="col">
          <div className="row ">
            <div className="col-12">
              <h1 class="display-4 ">#poesias</h1>
              <p class="lead">
                Procure aqui as Poesias feitas com carinho para meus amigos(as)
                e companheiros(as)
              </p>
            </div>
            <div className="col-md-12">
              <div className="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Busque por título da poesia"
                  aria-label="Busca"
                  aria-describedby="button-addon2"
                  value={poemaSearch}
                  onChange={e => setPoemaSearch(e.target.value)}
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                  >
                    Buscar +
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card-columns">
                {filteredPoesias.map(({ node }) => (
                  <PoemaCard key={node.id} poesia={node} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

// This page query loads all posts sorted descending by published date The
// `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: 100
      filter: {
        slug: { ne: "data-schema" }
        tags: { elemMatch: { slug: { eq: "poesias" } } }
      }
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`

export default PoemasPage
